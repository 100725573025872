import styled from 'styled-components'

export const TopContainer = styled.div`
   display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  background-image: url(https://images.unsplash.com/photo-1591955506264-3f5a6834570a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80);
  background-position: center;
  background-size: cover;
  row-gap: 4rem;
  text-align: center; 
  z-index: 1;
  background-color: linear-gradient( rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), linear-gradient(180deg, rgba(0,0,0,0.2), );
`;

export const TopBack = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

export const ImageBack = styled.div`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  
`;

export const TopContent = styled.div`
    display: grid;
    grid-template-columns: 4fr 3fr;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 5em;
    @media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
    }
`
export const TopH1 = styled.h1`
    color: #111;
    font-family: Bentham;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }
    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`

export const TopP = styled.p`
    margin-top: 24px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
    font-size: 28px;
    padding: 1em;
    text-align: center;
    max-width: 52ch;

    @media screen and (max-width: 768px){
        font-size: 24px;
    }

    @media screen and (max-width: 480px){
        font-size: 18px;
    }
`

export const Card1 = styled.div`
width: 100%;
height: 100%;
max-width: 440px;
color: #fff;
padding: 20px 40px;
text-align: center;
border: 1px solid rgba(255,255,255,0.3);
background: rgba(128,128,128,0.6);
border-radius: 20px;
box-shadow: 0 4px 30px rgba(0,0,0, 0.1);

@media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
    }

p{
    font-size: 20px;
    font-weight: 500;
    margin: 10px auto;
    max-width: 330px;
    color: #111
}
`

