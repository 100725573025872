import styled from "styled-components";

export const ImageContainer = styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;
`

export const ImageCarousel = styled.div`
list-style: none;
    display: flex;
    padding: 0px;
    margin: 0px;
    white-space: nowrap;
    transition: transform 0.2s ease 0s;
`
export const ImageUniq = styled.div`
    display: inline-block;
    width: 33.33%;
    flex-shrink: 0;
    -webkit-transition: -webkit-transform .5s ease;
   transition: transform .10s ease;


    :hover{
      -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    
    @media screen and (max-width: 820px) {
        :hover{
      -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    }


    `