import React from 'react'
import SocialBody from '../Components/socialBody/socialBody'
import { socialObj, socialObj2 , socialObj3, socialObj4, socialObj5 } from '../Components/socialBody/data'

const Social = () => {
  return (
    <>
        <SocialBody {...socialObj}/>
        <SocialBody {...socialObj2}/>
        <SocialBody {...socialObj3}/>
        <SocialBody {...socialObj4}/>
        <SocialBody {...socialObj5}/>
  </>
  )
}

export default Social