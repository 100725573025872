import React from 'react'
import ComoditiesBody from '../Components/comoditiesBody/comoditiesBody'
import {comoditiesObj,comoditiesObj2,comoditiesObj3} from '../Components/comoditiesBody/data'

const Comodities = () => {
  return (
    <>
      <ComoditiesBody {...comoditiesObj}/>
        <ComoditiesBody {...comoditiesObj2}/>
        <ComoditiesBody {...comoditiesObj3}/>
  </>
  )
}

export default Comodities