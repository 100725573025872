import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import {createBrowserRouter,RouterProvider} from "react-router-dom"
import Home from './pages';
import Historia from './pages/Historia';
import Construcao from './pages/Construcao';
import Assessoria from './pages/Assessoria';
import Comodities from './pages/Comodities';
import Social from './pages/Social';

const router = createBrowserRouter([

  {
    path:"/",
    element: <App/>,
    children:[
      {
        path:'/',
        element:<Home/>
      },
      {
        path:'/historia',
        element: <Historia/>
      },
      {
        path:'/construcao',
        element:<Construcao/>
      },
      {
        path:'/assessoria',
        element: <Assessoria/>
      },
      {
        path:'/comodities',
        element: <Comodities/>
      },
      {
        path:'/social',
        element: <Social/>
      },
    ]

  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);
