import styled from "styled-components";

export const InfoContainer = styled.div`
    color: #fff;
    background: #808080;
    background: ${({ lightBg }) => (lightBg ? '#808080' : 'transparent')};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-attachment:${({backBg}) => (backBg ? 'fixed': '#808080')};
    padding: 6rem 0;
    background-image:${({imageBg}) => (imageBg ? 'url(https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80)': '#808080')} ;
    gap: 1.6em;
    background-position: center;
    background-size: cover;
    row-gap: 4rem;

    @media screen and (max-width:768px){
        padding: 80px 0;
    }

    @media only screen and (max-device-width: 1366px) {
    .parallax-container {
    background-attachment: scroll;
  }
} 
`

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
`

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    background-color: #000;
    background-color: rgba(0,0,0,.5);
    color: #fff;
    border-radius: 1rem;
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px){
        grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1 col1' 'col2 col2'` : `'col1 col1' 'col2 col2'`)};
    }

`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
    
    
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`

export const TextWrap = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
    
`

export const TopLine = styled.p`
    color: #6C63FF;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #CCA351;


    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#111': '#fff')};
    font-family: 'Roboto', sans-serif;
    
`;

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`

