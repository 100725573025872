import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'

export const Navb = styled.nav`

    height: 80px;
    display: flex;
    margin-top: -80px;
    justify-content: center;
    align-items: center;
    position: sticky;
    background: ${({scrollLegal}) => (scrollLegal ? '#000' : 'rgba(0,0,0,0.5)')};
    font-size: 1rem;
    transition: 0.8s all ease;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`


export const NavContainer = styled.nav`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`

export const NavLogo = styled.nav`
    color: #fff;
    justify-self: flex-start;
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
    width: 130px;
    background: ${({scrollLegal}) => (scrollLegal ? '#000' : 'transparent')};
`

export const MobileIcon = styled.nav`
    display: none;

    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;   

    @media screen and (max-width:768px){
        display: none;

    }
`

export const NavItem = styled.li `
    height: 80px;
`

export const NavLinks = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor:pointer;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: ${({primary}) => (primary ? '#fff' : '#CCA351')}
    }

    &.active{
        border-bottom: 3px solid #6C63FF
    }
`

