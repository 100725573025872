export const assessoriaObj = {
    id:'Construção Civil',
    lightBg: false,
    backBg: true,
    imageBg: true,
    headline: 'Assessoria',
    description: 'No coração do sucesso do Grupo Seaplan reside uma equipe de assessoria que desenha um roteiro de colaboração e eficiência. Esta empresa líder na indústria da construção civil não apenas ergue estruturas impressionantes, mas também constrói uma cultura organizacional que valoriza cada membro da equipe como um ativo essencial para o crescimento e a inovação contínuos. A assessoria de equipe no Grupo Seaplan é mais do que apenas uma função operacional; é um princípio fundamental enraizado em todos os aspectos da empresa. A liderança, encabeçada por Carlos Alberto Garcia, entende a importância de uma visão compartilhada. Através de comunicação clara e estratégias de alinhamento, cada membro da equipe compreende não apenas suas responsabilidades individuais, mas também como seu trabalho se integra ao panorama geral dos projetos.',
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: false,
    img: require('../../assets/reuniao.jpeg'),
    alt: 'Eu'
}

export const assessoriaObj2 = {
    id:'Assessoria',
    lightText: false,
    backBg: true,
    lightTextDesc: false,
    imageBg: true,
    description: 'Uma assessoria de equipe eficaz não se trata apenas de delegação, mas também de empoderamento. No Grupo Seaplan , a confiança é depositada nas habilidades individuais e na experiência de cada membro da equipe. Isso não apenas cria um ambiente onde os talentos podem florescer, mas também alimenta a inovação. As ideias são valorizadas, e o espaço para a experimentação é respeitado, permitindo que a equipe explore novos métodos construtivos, materiais sustentáveis e abordagens tecnológicas vanguardistas.',
    dark: true,
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: true,
    img: require('../../assets/reuniao.jpg'),
    alt: 'Eu'
}

export const assessoriaObj3 = {
    id:'Comodities',
    lightText: true,
    backBg: true,
    imageBg: true,
    lightTextDesc: true,
    lightBg: false,
    description: 'Além de construir monumentos arquitetônicos, o Grupo Seaplan também investe no desenvolvimento humano. A assessoria de equipe aqui vai além do trabalho diário, proporcionando oportunidades para o crescimento pessoal e profissional. Programas de treinamento contínuo, mentorias e workshops são oferecidos para aprimorar habilidades técnicas e "soft skills". Isso não apenas resulta em uma equipe mais qualificada, mas também em indivíduos que se sentem valorizados e motivados. No Grupo Seaplan , a assessoria de equipe transcende o papel administrativo convencional. É um fator-chave para a criação de um ambiente de trabalho onde a colaboração, a inovação e o desenvolvimento são incentivados. A visão compartilhada, o empoderamento individual e as oportunidades de crescimento não apenas fortalecem a equipe interna, mas também impulsionam o Grupo Seaplan  a novas alturas de sucesso e liderança no cenário da construção civil.',
    dark: true,
    primary: true,
    buttonLabel: 'Formações',
    darkText: false,
    imgStart: false,
    img: require('../../assets/workshop.jpg'),
    alt: 'Eu'
}