import React from "react";
import Top from "../Components/heroBody";
import InfoGerais from "../Components/Informacao/informacao";
import { homeObj, homeObj2, homeObj3 } from "../Components/Informacao/data";
import '../input.css';
import MyImageCarousel from "../Components/ImagesLinks";
/* import Carrossel from "../Components/Carrossel" */



const Home = () =>{
    return(
        <>
        <Top />
        <InfoGerais {...homeObj}/>
        <InfoGerais {...homeObj2}/>
        <InfoGerais {...homeObj3}/>
        <MyImageCarousel/>
    {/*     <Carrossel/> */}
        </>
    )

}

export default Home;