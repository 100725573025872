import styled, { css } from "styled-components";

const media = {
    desktop: (...args) => css`
      @media (min-width: 870px) {
        ${css(...args)};
      }
    `
  };

export const FooterContainer = styled.footer`
    background-color: #000;

`

export const FooterWrapper = styled.div`
    padding: 48px 24px;
    display: grid;
    grid-template-columns: 200px 200px 200px 200px ;
    justify-content: space-evenly;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;

    
    @media screen and (max-width: 820px) {
      display: flex;
      flex-direction: column;

    }

`

export const SocialMedia = styled.section`
    max-width: 200px;
    width: 100%;
 `
export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    max-width: 1100px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
 `

export const Signed = styled.small`
    color: #fff;
    margin-bottom: 20px;
`

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70px;
`

export const SocialIconsLinks = styled.a`
    color: #fff;
    font-size: 26px;
`

export const Menu = styled.div`
  margin-bottom: 30px;
  align-items: center;
  min-width: 50%;
  ${media.desktop`
    min-width: unset;
    margin-bottom: 0;
    margin-right: 60px;
    margin-left: 60px;
  `}
`;

export const MenuHead = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.11;
  color: #CCA351;
`;

export const MenuLink = styled.a`
  display: block;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 2;
  color: #fff;
  ${media.desktop`
    white-space: nowrap;
  `}

  &:hover {
        transition: all 0.2s ease-in-out;
        color: ${({primary}) => (primary ? '#fff' : '#CCA351')}
    }
`;

export const FooterLogo = styled.div`
max-width: 200px;
`