import React, { useEffect, useState } from "react";
import { FaBars } from 'react-icons/fa'
import { Navb, NavContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks } from "./NavStyled.js";
import { animateScroll as scroll } from "react-scroll/modules";
import logoGif from '../../assets/logo2.gif';
import { Link } from "react-router-dom";

const Navbar = ({ toggle }) => {
    const [scrollLegal, setScrollLegal] = useState(false)

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollLegal(true)
        } else {
            setScrollLegal(false)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <>
            <Navb scrollLegal={scrollLegal}>
                <NavContainer>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks>
                            <Link to="/historia"> História</Link>
                                </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks>
                                <Link to="/construcao">Construção Civil</Link>
                                </NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavLogo onClick={toggleHome}>
                    <Link to="/">
                        <img src={logoGif}
                            alt="logo"
                        />
                        </Link>
                    </NavLogo>
                    <NavMenu>
                        <NavItem>
                            <NavLinks>
                            <Link to="/assessoria"> Assessoria</Link>
                                </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks>
                            <Link to="/comodities"> Comodities</Link>
                                </NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavContainer>
            </Navb>
        </>

    );
};

export default Navbar;