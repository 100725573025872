import React from 'react'
import ConstrucaoBody from '../Components/construcaoBody/construcaoBody'
import { construcaoObj, construcaoObj2 , construcaoObj3  } from '../Components/construcaoBody/data'

const Construcao = () => {
  return (
    <>
        <ConstrucaoBody {...construcaoObj}/>
        <ConstrucaoBody {...construcaoObj2}/>
        <ConstrucaoBody {...construcaoObj3}/>
  </>
  )
}

export default Construcao