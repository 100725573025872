export const socialObj = {
    id:'Construção Civil',
    lightBg: false,
    backBg: true,
    imageBg: true,
    headline: 'Area Social',
    description: 'O Grupo Seaplan em Parceria com o Instituto Liberty está desenvolvendo três Projetos sociais são eles TELEMEDICINA GRATUITA, ESPORTE E MÚSICA.',
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: false,
    img: require('../../assets/liberty.png'),
    alt: 'Eu'
}

export const socialObj2 = {
    id:'Construção Civil',
    lightBg: false,
    backBg: true,
    imageBg: true,
    headline: 'Telemedicina',
    description: ' o Grupo Seaplan atua no setor de doenças sexualmente transmissíveis com mais de um milhão de atendimentos. ',
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: false,
    img: require('../../assets/medicine.jpg'),
    alt: 'Eu'
}

export const socialObj3 = {
    id:'Assessoria',
    lightText: false,
    backBg: true,
    lightTextDesc: false,
    imageBg: true,
    headline: 'Esporte',
    description: ' o Grupo Seaplan está trabalhando no maior projeto social esportivo do Mundo e tudo começa com o projeto "CTzinho Revelando Talentos" dando início nas comunidades até os bairros nobres. Visando o grande déficit de jogadores nos clubes e altos custos na compra de jogadores, o projeto CTzinho abre as portas dando oportunidade a novos talentos, para isso o Grupo Seaplan está buscando um clube em Brasília e outro em  Portugal, para criar uma vitrine dando oportunidades aos  nossos atletas Brasileiro de se adaptarem em outro continente, facilitando as negociações com outros clubes europeus, O CEO Carlos Alberto Garcia com sua visão tríade de investidor, empreendedor e empresário observou que os altos preços dos jogadores de ponta chegaram em média 1 bilhão de Reais e isso faz com que os jogadores de base alcance um preço fora da realidade dos clubes de terceiro divisão levando os mesmo a grandes dívidas e falência total não só no Brasil e no mundo, mesmo com a criação da SAD em Portugal e SAF no Brasil não está resolvendo o problema dos principais Clubes do Mundo. Conforme a publicação no Jornal O Globo - Quarta feira 6.2.2019  o Grupo Seaplan deu início a pesquisa e roteiro da série Brasil Celeiro do Mundo e o esporte não fica de fora.   ',
    dark: true,
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: true,
    img: require('../../assets/sport.jpg'),
    alt: 'Eu'
}



export const socialObj4= {
    id:'Assessoria',
    lightText: false,
    backBg: true,
    lightTextDesc: false,
    imageBg: true,
    headline: 'Projeto Orchestra Seaplan',
    description: 'A Música é a combinação de ritmo, harmonia e melodia, para o CEO do Grupo faz parte da maior Orquestra de música do Mundo com mais de 500 mil musicos em vários países para Carlos Alberto Garcia a musica é a Arte de expressar sentimentos e isso já está no DNA do ser humano dando início no ventre de mãe. a criança já entende através das canções o que a sua mãe está falando e sentindo. O Grupo acredita que através desse PROJETO SOCIAL envolvendo Esportes e  Música poderá transformar uma nação em 10 anos. ',
    dark: true,
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: true,
    img: require('../../assets/orchestra.jpg'),
    alt: 'Eu'
}

export const socialObj5 = {
    id:'Comodities',
    lightText: true,
    backBg: true,
    imageBg: true,
    lightTextDesc: true,
    lightBg: false,
    headline: 'Investimentos',
    description: 'O Grupo Seaplan em Parceria com a OSLEC INVESTIMENTOS e Grandes plataformas financeiras, através das SBLCs  stand-by 3034 , SKRs emitidas pelas maiores Security house do Mundo com os ativos baseados em pedras preciosas e metais preciosos e outros, BGs  dos principais Banco do Mundo. Com essa estratégia o grupo cria seu próprio lastro financeiros para estruturar o consórcio coletivo. O sistema de consórcios coletivos é uma modalidade de compra baseada na união de pessoas físicas ou jurídicas, em grupo fechado. A finalidade é formar um fundo coletivo, por meio de autofinanciamento, à  compra de bens móveis duráveis, e execução de projetos seja na área de habitação, saúde, transporte, energia, mineração, agrícultura infraestruturas e outros.',
    dark: true,
    primary: true,
    buttonLabel: 'Formações',
    darkText: false,
    imgStart: false,
    img: require('../../assets/oslec.png'),
    alt: 'Eu'
}