import React from "react";
import { InfoContainer, InfoWrapper, InfoRow, Column1, TextWrap, Heading, Subtitle, Column2, ImgWrap, Img } from "./socialBodyStyled";

const SocialBody = ({ lightBg,backBg, imageBg, id, imgStart, lightText, darkText, topLine, headline, description, img, alt }) => {
    return (
        <InfoContainer imageBg={imageBg} backBg={backBg} lightBg={lightBg} id={id} >
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrap>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                        </TextWrap>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt} />
                        </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>

    )
}

export default SocialBody;