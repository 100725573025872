import React from "react";
import { FooterContainer,FooterLogo, Menu, MenuHead, MenuLink, FooterWrapper, SocialMedia, SocialMediaWrap, Signed, SocialIcons, SocialIconsLinks } from "./footerStyled";
import { FaLinkedin, FaInstagram } from 'react-icons/fa'
import LogoGif from '../../assets/logo2.gif'

const Footer = () => {
    const menu = [
      
        {
            name: "Links Úteis",
            links: [
                { title: "História", url: "/historia" },
                { title: "Construção Civil", url: "/construcao" },
                { title: "Assessoria", url: "/assessoria" },
                { title: "Comodities", url: "/comodities" },
                { title: "Área Social", url: "/social" },
            ]
        },
        {
            name: "Entre em contato",
            links: [
                { title: "contato@gruposeaplan.com.br", url: "https://mail.google.com/mail/u/1/#inbox?compose=new" },
                { title: "cfoseaplan@gmail.com", url: "https://mail.google.com/mail/u/1/#inbox?compose=new" },
            ]
        },

        {
            name: " Links Associados",
            links: [
                { title: "Oslec Investimentos", url: "https://www.oslecinvestimentos.com/" },
                { title: " Instituto Liberty", url: "http://institutoliberty.org.br/" },
            ]
        }
    ];
    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterLogo>
                <a href="/"><img src={LogoGif} alt='logo'/></a>
                </FooterLogo>

                {menu.map(({ name, links }) => (
          <Menu>
            <MenuHead>{name}</MenuHead>
            {links.map(({ title, url }) => (
              <MenuLink href={url}>{title}</MenuLink>
            ))}
          </Menu>
        ))}
                <SocialMedia>
                    <SocialMediaWrap>

                        <SocialIcons>
                            <SocialIconsLinks href='https://www.linkedin.com/company/grupo-seaplan/about/' target="_blank" arial-label="LinkedIn">
                                <FaLinkedin/>
                            </SocialIconsLinks>
                            <SocialIconsLinks href='https://www.instagram.com/' target="_blank" arial-label="Instragam">
                                <FaInstagram />
                            </SocialIconsLinks>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>

        
                
            </FooterWrapper>
            <Signed>Todos os direitos reservados © {new Date().getFullYear()} Grupo Seaplan ltda. </Signed>
        </FooterContainer>
    )

}

export default Footer;