import React from "react";
import { ImageContainer, ImageCarousel, ImageUniq } from "./imagesStyled";
import informacao1 from "../images/informacao1.jpg"
import informacao2 from "../images/informacao2.jpg"
import informacao3 from "../images/informacao3.jpg"

const MyImageCarousel = () => {

  return (
    <ImageContainer>
      <ImageCarousel>
        <ImageUniq>
          <a href="/construcao">
          <img src={informacao1} alt="Construção Civil"/>
            </a>
          </ImageUniq>

          <ImageUniq>
          <a href="/assessoria">
          <img src={informacao2} alt="Assessoria"/>
          </a>
          </ImageUniq>

          <ImageUniq>
          <a href="/comodities">
          <img src={informacao3} alt="Comodities"/>
          </a>
          </ImageUniq>
      </ImageCarousel>

    </ImageContainer>



  );
};

export default MyImageCarousel;