import React from "react"
import { SidebarContainer, Icon, CloseIcon, SidebarLink, SidebarWrapper, SidebarMenu } from './sidebarStyled'

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                  
                    <SidebarLink >
                        <a href="/historia">
                        História
                        </a>
                    </SidebarLink>

                    <SidebarLink  >
                        <a href="/construcao">
                        Construção Civil
                        </a>
                    </SidebarLink>
                    <SidebarLink>
                        <a href="/assessoria">
                        Assessoria
                        </a>
                    </SidebarLink>
                    <SidebarLink  >
                        <a href="/comodities">
                        Comodities
                        </a>
                    </SidebarLink>

                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>

    );
};

export default Sidebar