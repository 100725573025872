export const historyObj = {
    id:'Construção Civil',
    lightBg: false,
    backBg: true,
    imageBg: true,
    headline: 'História',
    description: 'A família Garcia chegou de Portugal ao Brasil, no século XIX que teve como sua principal atividade econômica tropeirismo. Apesar de não terem se caracterizado como grandes produtores de café, os Garcia de Mattos foram grandes possuidores de terras e de homens. Foi através da acumulação de capital como tropeiros que esta família expandiu suas fronteiras agrícolas por várias freguesias da Zona da Mata mineira. Considerações iniciais, “O tropeiro” foi de fundamental importância para o desenvolvimento econômico da Zona da Mata no século XIX, onde seu protagonismo está relacionado ao processo de escoamento da produção agrícola para abastecimento da Corte antes da chegada das estradas de ferro. Os Garcia possuiam também ações da estrada de Ferro, mesmo com receio de que a ferrovia poderia atrapalhar os seus negócios, mantiveram o  sentido de progresso e avançaram. Nesse inventário, com o desenvolvimento da cafeicultura, os burros e bestas foram utilizados para transporte do café para o porto, na província do Rio de Janeiro.',
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: false,
    img: require('../../assets/fumaca.jpg'),
    alt: 'Eu'
}

export const historyObj2 = {
    id:'Assessoria',
    lightText: false,
    backBg: true,
    lightTextDesc: false,
    imageBg: true,
    description: 'Além do transporte de produtos, os tropeiros exerceram outro papel, o de transportar também informações. Com toda essa diversificação de trabalho, a família Garcia ficou conhecida em 1840 como a família tríade, com três seguimentos de trabalhos.  Antes de se tornar o renomado Grupo Seaplan, a trajetória de José Bernardo Garcia Filho  teve início de forma distinta em Minas Gerais, nos anos iniciais na década de 1929  já no Rio de Janeiro, ele fundou uma empresa dedicada à divisão de terraplanagem e loteamentos, criando oportunidades urbanas para um número crescente de pessoas em busca de residências. Com uma visão inovadora, Garcia moldou bairros inteiros, deixando um impacto duradouro na paisagem urbana da cidade, em 1970 surge a Seaplan Serviço de Engenharia e Arquitetura e Planejamento fazendo a família Garcia expandir seus seguimentos. ',
    dark: true,
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: true,
    img: require('../../assets/comoditie.jpeg'),
    alt: 'Eu'
}

export const historyObj3 = {
    id:'Comodities',
    lightText: true,
    backBg: true,
    imageBg: true,
    lightTextDesc: true,
    lightBg: false,
    description: 'Além de suas atividades na construção civil e commodities, o Grupo Seaplan agora sob a liderança de Carlos Alberto Garcia expandiu-se para o setor de consultoria e assessoria. Reconhecendo as demandas crescentes por orientação especializada no ambiente empresarial em constante evolução, a empresa passou a oferecer serviços de consultoria estratégica, abrangendo diversas áreas. A história de Carlos Alberto Garcia e sua jornada empresarial é uma narrativa de visão, inovação e compromisso. Sua transição de empreendimentos com foco na quarta revolução industrial o Grupo Seaplan aposta no setor de  infraestruturas, aproximando o online e o offline, nos setores da Construção civil, Rodovias,  Ferrovias, Construção de Portos e Aéreoportos, Construção de Oleodutos, Gasodutos e Energia condicionamento e comissionamento de sistemas e instrumentos.',
    dark: true,
    primary: true,
    buttonLabel: 'Formações',
    darkText: false,
    imgStart: false,
    img: require('../../assets/aeroporto.jpg'),
    alt: 'Eu'
}