import React from "react";
import {  TopContainer, TopContent, TopH1, TopP, Card1 } from "./heroStyled";

const Top = () => {
    return(
        <TopContainer id="home">
            <TopContent>
                <Card1>
                <TopH1> História</TopH1>
                <TopP> A Seaplan é uma empresa  líder no setor de construção civil, especializada em projetos inovadores e sustentáveis que abrangem desde edifícios residenciais e comerciais até infraestruturas complexas. Fundada há quase um século, a empresa tem sido reconhecida por sua excelência em engenharia, design arrojado e compromisso com práticas ambientais responsáveis.</TopP>
                </Card1>
                <Card1>
                <TopP> Com sede em uma metrópole moderna, a Seaplan se destaca pela sua abordagem visionária na criação de espaços que harmonizam a funcionalidade com a estética. Seus projetos icônicos têm transformado horizontes urbanos, incorporando tecnologias avançadas e soluções sustentáveis que minimizam o impacto ambiental.</TopP>
                <TopP><a href="/historia">Saiba mais!</a></TopP>
                </Card1>
                
            </TopContent>
        </TopContainer>

    );
};

export default Top;