import React, {useState} from "react";
import './input.css';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import { Outlet } from 'react-router-dom';
import Sidebar from "./Components/sidebar";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
      setIsOpen(!isOpen)
  }
  return (
    
    <>
    <Sidebar isOpen={isOpen} toggle={toggle}/>
    <Navbar toggle={toggle} />
    <Outlet/>
    <Footer/>
    </>
  );
}

export default App;

