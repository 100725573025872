import React from 'react'
import {historyObj,historyObj2,historyObj3} from '../Components/historyBody/data'
import HistoryBody from '../Components/historyBody/historyBody'



const Historia = () => {
  return (
  <>
        <HistoryBody {...historyObj}/>
        <HistoryBody {...historyObj2}/>
        <HistoryBody {...historyObj3}/>
  </>
  )
}

export default Historia