export const homeObj = {
    id:'Construção Civil',
    lightBg: true,
    backBg: false,
    imageBg: false,
    headline: 'Construção Civil',
    description: 'A construção civil é um setor crucial da economia que abrange desde projetos residenciais até grandes infraestruturas. Englobando diversos profissionais, como engenheiros e arquitetos, o processo começa com o planejamento e projeto, seguido pela obtenção de licenças. A execução da obra é supervisionada, do início ao acabamento. Essa indústria impulsiona o crescimento econômico, gera empregos e atende às demandas por habitação e infraestrutura, apesar dos desafios de sustentabilidade e segurança.',
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: false,
    img: require('../images/informacao1.jpg'),
    alt: 'Eu'
}

export const homeObj2 = {
    id:'Assessoria',
    lightText: false,
    backBg: true,
    lightTextDesc: false,
    imageBg: true,
    headline: 'Assessoria',
    description: 'A assessoria empresarial é um serviço prestado por profissionais especializados para auxiliar empresas em diversas áreas. Seu principal objetivo é fornecer orientação estratégica e soluções personalizadas para melhorar o desempenho do negócio. Esses assessores podem atuar em assuntos como gestão, finanças, recursos humanos, marketing, jurídico e tecnologia, adaptando suas abordagens às necessidades específicas de cada cliente. Através da análise de dados, identificação de oportunidades, mitigação de riscos e implementação de melhores práticas, a assessoria empresarial contribui para o crescimento sustentável e o alcance dos objetivos corporativos.',
    dark: true,
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: true,
    img: require('../images/informacao2.jpg'),
    alt: 'Eu'
}

export const homeObj3 = {
    id:'Comodities',
    lightText: true,
    backBg: false,
    imageBg: false,
    lightTextDesc: true,
    lightBg: true,
    headline: 'Comodities',
    description: 'O serviço voltado para commodities é uma solução especializada que atende às necessidades de produtores, compradores e investidores no mercado de commodities. Com foco em commodities como grãos, metais, energia e outros recursos naturais, esse serviço oferece suporte abrangente, incluindo a facilitação de negociações, logística eficiente, análise de mercado, gerenciamento de riscos e otimização de cadeias de suprimentos. Com base em análises detalhadas e a experiência no setor, busca-se maximizar a eficiência e a lucratividade das operações, fornecendo informações valiosas para tomar decisões estratégicas bem informadas em um mercado global dinâmico e competitivo.',
    dark: true,
    primary: true,
    buttonLabel: 'Formações',
    darkText: false,
    imgStart: false,
    img: require('../images/informacao3.jpg'),
    alt: 'Eu'
}