import React from 'react'
import AssessoriaBody from '../Components/assessoriaBody/assessoriaBody'
import { assessoriaObj, assessoriaObj2,assessoriaObj3 } from '../Components/assessoriaBody/data'

const Assessoria = () => {
  return (
    <>
      <AssessoriaBody {...assessoriaObj} />
      <AssessoriaBody {...assessoriaObj2} />
      <AssessoriaBody {...assessoriaObj3} />
    </>
  )
}

export default Assessoria