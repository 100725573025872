export const construcaoObj = {
    id:'Construção Civil',
    lightBg: false,
    backBg: true,
    imageBg: true,
    headline: 'Construção Civil',
    description: 'O Grupo Seaplan , um líder na indústria da construção civil, tem se destacado como um farol de inovação e sustentabilidade. Sob a direção visionária de seu fundador, José Bernardo Garcia Filho, a empresa tem revolucionado a abordagem tradicional da construção, fundindo técnicas antigas com as mais recentes inovações tecnológicas. Com uma equipe dedicada de engenheiros e arquitetos, o Grupo Seaplan  é conhecido por sua pesquisa incessante em busca de novos materiais, métodos construtivos e soluções ecologicamente conscientes, resultando em projetos que variam de arranha-céus imponentes a empreendimentos residenciais energeticamente eficientes.',
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: false,
    img: require('../../assets/niteroi.jpg'),
    alt: 'Eu'
}

export const construcaoObj2 = {
    id:'Assessoria',
    lightText: false,
    backBg: true,
    lightTextDesc: false,
    imageBg: true,
    description: 'O compromisso da SEAPLAN com a sustentabilidade não apenas se reflete em suas edificações, mas também em suas operações e impacto na comunidade. A empresa adota tecnologias verdes, como painéis solares integrados e sistemas de reciclagem de água, demonstrando seu comprometimento com práticas ambientalmente responsáveis. Além disso, programas de responsabilidade social corporativa capacitam jovens carentes e contribuem para projetos de infraestrutura em áreas desfavorecidas. A visão da SEAPLAN ultrapassa fronteiras, com projetos marcantes em diversos países, elevando os padrões globais de construção e inspirando a indústria a construir um mundo mais sustentável e inovador.',
    dark: true,
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: true,
    img: require('../../assets/UNIG.jpg'),
    alt: 'Eu'
}

export const construcaoObj3 = {
    id:'Comodities',
    lightText: true,
    backBg: true,
    imageBg: true,
    lightTextDesc: true,
    lightBg: false,
    description: 'O Grupo SEAPLAN  não é apenas uma empresa de construção, mas sim um catalisador de mudanças positivas no setor. Sua busca incessante pela excelência técnica, inovação e sustentabilidade estabeleceu um novo paradigma na construção civil, reforçando a ideia de que é possível construir não apenas estruturas físicas impressionantes, mas também um futuro melhor para o planeta e para as comunidades onde atua.',
    dark: true,
    primary: true,
    buttonLabel: 'Formações',
    darkText: false,
    imgStart: false,
    img: require('../../assets/aeroporto.jpg'),
    alt: 'Eu'
}