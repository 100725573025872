export const comoditiesObj = {
    id:'Construção Civil',
    lightBg: false,
    backBg: true,
    imageBg: true,
    headline: 'Comodities',
    description: 'O serviço de commodities para plantação de soja é fundamental na indústria agrícola global, conectando produtores a mercados internacionais. Isso permite acesso a preços competitivos, informações de mercado, serviços financeiros e instrumentos de hedge. Além disso, facilita a rastreabilidade e práticas sustentáveis, atendendo às demandas por transparência. Assim, esse serviço desempenha um papel crucial ao impulsionar a economia, a produção de alimentos responsáveis e a sustentabilidade ambiental.',
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: false,
    img: require('../../assets/plantacao.jpg'),
    alt: 'Eu'
}

export const comoditiesObj2 = {
    id:'Assessoria',
    lightText: false,
    backBg: true,
    lightTextDesc: false,
    imageBg: true,
    description: 'A mineração de ferro é essencial para a produção global de aço, com o minério extraído e processado para atender à demanda industrial. Isso influencia a construção, manufatura e setor automotivo. Embora beneficie a economia e gere empregos, também apresenta desafios ambientais, incentivando a busca por métodos mais sustentáveis. A indústria busca equilibrar as necessidades industriais com a responsabilidade ambiental, abordando questões como a recuperação de áreas degradadas e a adoção de tecnologias limpas.',
    dark: true,
    primary: true,
    buttonLabel: 'Certificações',
    darkText: false,
    imgStart: true,
    img: require('../../assets/comoditie.jpeg'),
    alt: 'Eu'
}

export const comoditiesObj3 = {
    id:'Comodities',
    lightText: true,
    backBg: true,
    imageBg: true,
    lightTextDesc: true,
    lightBg: false,
    description: 'A mineração de esmeraldas se insere no âmbito das commodities, que são matérias-primas amplamente negociadas globalmente. Esmeraldas, pedras preciosas verdes, são extraídas de depósitos minerais através de processos de perfuração, detonação e escavação. Países como Colômbia, Brasil, Zâmbia e Zimbábue são importantes produtores. A qualidade das esmeraldas é avaliada por critérios como cor e clareza. A indústria enfrenta desafios ambientais, éticos e de comércio ilegal, enquanto a demanda por esmeraldas pode variar de acordo com tendências culturais e econômicas.',
    dark: true,
    primary: true,
    buttonLabel: 'Formações',
    darkText: false,
    imgStart: false,
    img: require('../../assets/mineracaoEsmeralda.jpeg'),
    alt: 'Eu'
}